export const environment = {
  appHost: 'https://staging.hub.veratrak.com',
  apiHost: 'https://ipebkhaqaf.execute-api.eu-west-1.amazonaws.com/Prod',
  awsHost:
    'https://d-936748b868.awsapps.com/start/#/console?account_id=796677305611&destination=',
  cognito: {
    poolName: 'watchtower-staging',
    clientId: '1ercoljqgfc3on014d6r4cr63s',
    redirect: 'https://staging.watchtower.hub.veratrak.com',
  },
};
